<template>
  <!-- 问题处理类型 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('ycgl_wenTiChuLiLeixin:add')"
        class="itemBtn btnColor"
        @click="addQuestionDeal(1)"
        >新建处理类型</el-button
      >
      <el-button
        v-if="hasPermiss('ycgl_wenTiChuLiLeixin:update')"
        class="itemBtn btnColor"
        @click="addQuestionDeal(0)"
        >修 改</el-button
      >
      <!--<el-dropdown @command="handleCommand_dc">-->
      <!--<el-button class="dropdownBtn">-->
      <!--导出<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
      <!--</el-button>-->
      <!--<el-dropdown-menu slot="dropdown">-->
      <!--<el-dropdown-item command="dc">导出</el-dropdown-item>-->
      <!--<el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>-->
      <!--<el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>-->
      <!--</el-dropdown-menu>-->
      <!--</el-dropdown>-->
    </div>
    <!-- 表格 -->
    <el-table
      :max-height="tableHeigth"
      ref="mytable"
      @row-click="rowclick"
      :header-cell-style="{ background: '#f0f0f0' }"
      :data="tableData"
      stripe
      style="width: 100%"
      size="small"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        fixed="left"
        label="序号"
        align="center"
        type="index"
        width="55"
      ></el-table-column>
      <el-table-column
        fixed="left"
        type="selection"
        align="center"
        width="55"
      ></el-table-column>
      <el-table-column
        prop="handleTypeName"
        label="处理名称"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="isShowShow"
        label="是否展示"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="sort"
        label="排序"
        align="center"
        min-width="100"
      ></el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <paging
      :pageNum="pageStart"
      :total="pageCount"
      :sizeList="sizeList"
      :size="pageTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging>
    <!-- 新建异常类型弹窗 -->
    <el-dialog
      title="新建异常类型"
      :visible.sync="isDT"
      width="40%"
      :close-on-click-modal="false"
      center
      :before-close="resetForm"
    >
      <div class="addForm">
        <el-form
          ref="formData"
          size="small"
          :model="formData"
          :rules="formDataRule"
          label-width="80px"
        >
          <el-form-item label="处理名称" prop="handleTypeName">
            <el-input
              v-model="formData.handleTypeName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否展示" prop="isShow">
            <el-select v-model="formData.isShow" placeholder="请选择">
              <el-option label="隐藏" :value="0"></el-option>
              <el-option label="展示" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="formData.sort" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="add" size="mini">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";

export default {
  components: {
    paging,
  },
  name: "questionDealType",
  data() {
    return {
      tableHeigth: "",
      tableData: [],
      pageStart: 1,
      pageCount: 0,
      pageTotal: 50,
      sizeList: [10, 20, 50, 100, 200],
      isDT: false,
      formData: {
        handleTypeName: "",
        isShow: "",
        sort: "",
      },
      formDataRule: {
        handleTypeName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        isShow: [{ required: true, message: "请选择", trigger: "change" }],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      singleData: [],
    };
  },
  created() {
    this.getData();
    this.fetTableHeight();
  },
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    getData() {
      //  获取数据
      this.fetTableHeight();
      Api.questionHandleTypeList({
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
      }).then((res) => {
        if (res.data.status === "success") {
          this.tableData = res.data.result.data;
          this.pageCount = res.data.result.pageCount;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    addQuestionDeal(num) {
      //  新建问题处理类型
      if (num !== 1) {
        if (this.singleData.length === 1) {
          Api.questionHandleTypeDetail({
            questionHandleTypeId: this.singleData[0].questionHandleTypeId,
          }).then((res) => {
            if (res.data.status === "success") {
              this.formData = res.data.result;
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          this.$message.warning("请选择一条数据");
          return;
        }
      }
      this.isDT = true;
    },
    add() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.formData.questionHandleTypeId) {
            let param = {
              questionHandleTypeId: this.formData.questionHandleTypeId,
              handleTypeName: this.formData.handleTypeName,
              isShow: this.formData.isShow,
              sort: this.formData.sort,
            };
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.updateQuestionHandleType(param).then((res) => {
              if (res.data.status === "success") {
                this.$message.success(res.data.message);
                this.isDT = false;
                this.getData();
                this.resetForm();
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            let param = {
              handleTypeName: this.formData.handleTypeName,
              isShow: this.formData.isShow,
              sort: this.formData.sort,
            };
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.addQuestionHandleType(param).then((res) => {
              if (res.data.status === "success") {
                this.$message.success(res.data.message);
                this.isDT = false;
                this.getData();
                this.resetForm();
              } else {
                this.$message.error(res.data.message);
              }
            });
          }
        }
      });
    },

    resetForm() {
      this.$refs["formData"].resetFields();
      this.isDT = false;
      this.formData = {
        handleTypeName: "",
        isShow: "",
        sort: "",
      };
    },
    handleSelectionChange(e) {
      this.singleData = e;
    },
    handleSizeChange(val) {
      this.pageTotal = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getData();
    },
    //    -------- 设置表格高度 ----------
    resetHeight() {
      // 重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },

    fetTableHeight() {
      // 设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },

    getHeight() {
      // 获取表格高度
      let windonHeight = document.body.clientHeight;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      let pagsHeigth = 60; //分页组件
      var otherHeight = btnListHeight + pagsHeigth + 30;
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
  },
};
</script>

<style scoped lang="scss">
.addForm {
  width: 100%;

  .el-form-item {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 16px;
  }
}
</style>
